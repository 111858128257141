<template>
    <div class="personalContent">
        <div class="personalHead">
            <i-icon name="icon-user-circular" :size="56" />
            <div class="personalName">
                <div class="name">{{ userMessage.dspName }}</div>
                <div class="phone">{{ userMessage.phoneNumber }}</div>
            </div>
            <img class="personalVersionBg" src="../assets/img/personal.png" />
            <span class="personalVersion">当前版本{{ getVersion() }}</span>
        </div>
        <div class="personalField">
            <i-skeleton v-if="isFirst" :row="10" />
            <div v-for="(item, index) in fieldList" class="row-item"
                :style="index == 1 ? 'border-bottom: 5px solid #fafafa' : ''" :key="index" @click="fieldClick(item)">
                <div class="title">
                    <i-icon :name="item.icon" :color="item.color"></i-icon>
                    {{ item.title }}
                </div>
                <div class="right">{{ item.right }}</div>
            </div>
            <div class="loginOutRow" v-if="getCookie('tenant') !== 'msd'">
                <i-button block size="normal" @click="openMsg('logoutAsync')"> 退出登录 </i-button>
            </div>
        </div>
    </div>
</template>

<script>
// import jsBridge from "smart-javascript-bridge";
// import { removeCookie } from "tiny-cookie";
import { iLogout } from "@/plugins";
import {getCookie} from "tiny-cookie";

export default {
    name: "personal",
    data() {
        return {
            getCookie,
            fieldList: [],
            msgType: "",
            isFirst: true,
            visible: false,
            userMessage: {},
            tenantCode: this.$smartStorage.get("tenant") || this.$cookie.get("tenant"),
            platform: this.$smartStorage.get("platform") || this.$cookie.get("platform"),
        };
    },
    created() {
        this.getSetting();
        this.getMe();
        // this.jsBridge = new jsBridge({
        //     alert: (e) => {
        //         this.$itoast(e);
        //     },
        // });
    },
    methods: {
        getMe() {
            this.$service.GetMe({}).then((res) => {
                if (res.success) {
                    this.userMessage = res.content;
                }
            });
        },
        getSetting() {
            let param = {
                collection: "cfg-ievent-personal-options",
                filter: {
                    tenantCode: this.tenantCode,
                    tenantId: this.$cookie.get("tenant_id") || "",
                    userId: { $in: [sessionStorage.getItem("userId") || "*", "*"] },
                },
                projection: {
                    _id: 0,
                    options: 1,
                },
            };
            this.$service.GetSettings(param).then((res) => {
                this.isFirst = false;
                if (res.success) {
                    this.fieldList = res.content?.options || [];
                }
            });
        },
        //打开弹框
        openMsg(type) {
            let messageTxt = {
                logoutAsync: "是否确认退出登录?",
                clearAlipayId: "是否确认清除授权？",
            };
            this.msgType = type;
            this.$idialog
                .confirm({
                    title: "提示",
                    message: messageTxt[type],
                })
                .then(() => {
                    // on confirm
                    this[this.msgType]();
                })
                .catch(() => {
                    // on cancel
                });
        },
        //获取版本号
        getVersion() {
            const version = this.$smartStorage.get("version") || this.$cookie.get("version");
            return "V" + (version || "1.0.0");
        },
        //打开webApp或触发回调
        fieldClick(item) {
            // this.getMe();
            let data;
            if(item.app=="webapp-iaccount"){
                data="&tenant="
            }if(item.app=="webapp-ihelper"){
                data="?tenant="
            }
            item.params = data + this.tenantCode + "&platform=" + this.platform;
            // if (["webapp-iaccount", "webapp-ihelper"].includes(item.app)) {
            //     item.params = &tenant= + this.tenantCode + "&platform=" + this.platform;
            // }
            console.log('item====', item)
            if (item.app) {
                this.openWebApp(item);
            } else if (item.callBack) {
                this[item.callBack]();
            }
        },
        openWebApp(item) {
            let appData = {
                callback: this.getMe,
            };
            let appConf = {
                webAppAlias: item.app,
                path: item.path,
                params: item.params,
            };
            this.$publicFun.openWebAppCate(appData, appConf, item.noSaveCacheData);
        },
        clearAlipayIdM(sid) {
            let param = {
                userId: this.$cookie.get("userId"),
                sid: sid
            }
            this.$service.MemberUnBind(param).then((res) => {
                if (res.success) {
                    this.$itoast('清除成功！')
                }
            });
        },
        //清除支付授权
        clearAlipayId() {
            this.$idialog
                .confirm({
                    message: '是否确认清除授权?',
                    title: "提示"
                })
                .then(() => {
                    let params = {
                        collection: "cfg-imeal-mealrules",
                        useCaching: false,
                        cachingKey: "",
                        filter: {
                            tenant_id: this.$cookie.get('tenant_id') || this.$cookie.get('tenantId'),
                        },
                    };
                    this.$service.GetSettings(params).then((res) => {
                        if (res.success) {
                            res.content.sid && this.clearAlipayIdM(res.content.sid)
                        }
                    });
                })

        },
        //退出登陆
        async logoutAsync() {
            const Logout = new iLogout()
            Logout.logoutAsync()
        },
        // clearCacheCallback(urllogout) {
        //     // console.log('openWebappUrl=', urllogout);
        //     if (window.flutter_inappwebview) {
        //         let params = {
        //             url: urllogout,
        //         };
        //         window.flutter_inappwebview.callHandler("SmGeneral.openNewWebSiteAndRemove", JSON.stringify(params));
        //     } else {
        //         this.jsBridge.openWebapp({
        //             loadUrl: urllogout,
        //             ignoreCookie: true,
        //         });
        //         this.jsBridge.close();
        //     }
        // },
    },
};
</script>

<style lang="less" scoped>
.personalContent {
    height: calc(100vh - 0.59rem);
    box-sizing: border-box;
    background: #fff;
    padding: 1.9rem 0 0 0;

    .personalHead {
        display: flex;
        align-content: center;
        font-size: 0.14rem;
        padding: 0.8rem 0.2rem 0.5rem 0.2rem;
        margin-bottom: 0.2rem;
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 2;
        background: #fff;

        .personalName {
            margin-left: 0.2rem;
            .name {
                font-size: 0.19rem;
            }
            .phone {
                color: #8f9397;
                font-size: 0.14rem;
                margin-top: 0.1rem;
            }
        }

        .personalVersionBg {
            width: 1.5rem;
            height: 1.5rem;
            position: absolute;
            right: 0;
            top: 0;
            z-index: 1;
        }

        .personalVersion {
            position: absolute;
            right: 0.4rem;
            top: 0.9rem;
            background: #fd5b21;
            z-index: 2;
            padding: 0.05rem 0.05rem 0.05rem 0.15rem;
            border-top-left-radius: 0.3rem;
            border-bottom-left-radius: 0.3rem;
            font-size: 0.12rem;
            color: white;
        }
    }

    .personalField {
        padding-bottom: 1rem;
        overflow-y: scroll;

        .row-item {
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 0.2rem 0.25rem;

            svg {
                display: block;
            }

            .title {
                display: flex;
                align-items: center;
                font-size: 0.16rem;
                color: #13161b;

                svg {
                    margin-right: 10px;
                }
            }

            .right {
                float: left;
                font-size: 0.14rem;
                color: #494949;
            }
        }

        .loginOutRow {
            padding: 1rem 0.5rem;
            background: #fff;
            margin-top: -0.2rem;

            .personalLoginOut {
                width: 60%;
                height: 0.45rem;
                line-height: 0.45rem;
                border-radius: 2rem;
                margin: 0 auto;
                font-size: 0.15rem;
                color: #90939a !important;
                background: #fff !important;
                border: 1px solid #e6e7e9;
                text-align: center;
            }
        }
    }
}
</style>
